import React from "react"
import { Link } from "gatsby"
import Skyline from "../components/skyline"
import Layout from "../components/layout"
import SEO from "../components/seo"

// const onSubmit = (e) => {
//   e.preventDefault();
//   console.log("submitting the form!")
// }

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <div id="bannerDiv">
      <Skyline className="skyline-pic"/>
        <div className="imageText">
          <h1>Web Development Services in the Pacific Northwest</h1>
        </div>
    </div>
    <div id="homeText">
      <h2>Build your online presence with confidence</h2>
      <p>We have experience creating websites for small businesses, startups, personal portfolios, and more.</p>
      <h2>Accomplish more without the cost of hiring full-time</h2>
      <p>We love to work on short-term website projects to help you achieve your business goals.</p>
      <div id="CTA">
        <h2>Have a project in mind?</h2>
        <p> Let's talk about it! <Link to="#contact">Contact us now</Link> for a free consultation.</p>
      </div>
    </div>
    <div id="aboutUs">
      <h1 className="page-header">About us</h1>
      <p className="goalText"><i>Snowgill Consulting is passionate about building professional, modern websites while delivering a fantastic customer experience.</i> </p>
      <p className="about-text">Our developers are experienced in building memorable websites based on custom designs and unique needs. We are confident that our relentless work ethic, efficient organization skills, inspiring vision and positive attitude will make you feel valued as we bring your project to life.</p>
      <p className="about-text">Located in Vancouver, BC, we are happy to meet clients locally. We are also comfortable working remotely.</p>
      <p className="about-text">If you have any questions about services offered, please <Link to="#contact">contact us</Link> through our online form and we will be happy to answer any of your questions.</p>
    </div>
    <div id="services">
      <h1 className="page-header">Services</h1>
        <div className="serviceItem">
          <div className="serviceImg s1"></div>
          <h4>Custom Websites</h4>
          <p>Mobile friendly, beautifully designed, and the perfect way to share your work with the world.  </p>
        </div>
        <div className="serviceItem">
          <div className="serviceImg s2"></div>
          <h4>Modern Apps</h4>
          <p>User login and accounts, payment processing, database integration -- everything you need for a fully functional application.  </p>
        </div>
        <div className="serviceItem">
          <div className="serviceImg s3"></div>
          <h4>Project work</h4>
          <p>Talk to us about your needs! We can add features, edit pages, assist with written content, and more.</p> 
        </div>
        <div className="design">
          <div className="designImg"></div>
          <h4>Design Services</h4>
          <p id="designP1">We love to bring your designs to life. Share your wireframes and we'll build it.</p> 
          <p id="designP2">Don't have a design figured out yet? We can help you create a look that you'll love. </p>
        </div>
    </div>
    <div id="process">
      <h1 className="page-header">Our Process</h1>
      <p className="goalText"><i>The first step is to have a conversation about your goals and needs.</i></p>
      <p>We know this is an investment in your future, and together we will develop a plan to help you achieve your website dreams. </p>
      <p>From there, we will create a proposal that outlines your design choices, timeline, budget, and other important details of this project.</p>
      <p>Ready to get started? <Link to="#contact">Contact us</Link> below!</p>
    </div>
    <div id="contact">
      <h1 className="page-header">Contact us</h1>
      <h3 className="secondary-header">We would love to hear about your website goals.</h3>
      <p>Please allow 2-3 business days for a response.</p>
      <form data-netlify="true" netlify-honeypot="bot-field" encType="application/x-www-form-urlencoded" method="POST" action="/success" name="contactform">
        <input type="hidden" name="form-name" value="contactform" />
        <label> Name:</label>
        <input name="username" type="text" required></input>
        <label>Email:</label>
        <input name="email" type="email" required></input>
        <label>Your project details:</label>
        <textarea name="details" required></textarea>
        <button type="submit">send message</button>
      </form>
      <p style={{fontSize: `25px`}}><i>We look forward to working with you!</i></p>
    </div>

    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
    </div>
  </Layout>
)

export default IndexPage
