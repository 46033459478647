import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


const Skyline = () => (
    <StaticQuery
      query={graphql`
        query {
          placeholderImage: file(relativePath: { eq: "sunset-skyline.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 10000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <Img className="skylinepic" fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
  )
  export default Skyline